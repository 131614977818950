import React from 'react'
import { classNames } from 'utils'
import { LogoBlock, TableCell } from 'routes/components/Shared'

export const TableRowMobile = ({ data, rowKey, isPrimary }) => {
  const dataValue = data[rowKey]

  return (
    <div
      className={classNames([
        'table__block-row',
        'table__block-row--data',
        isPrimary && 'table__block-row--primary',
      ])}
    >
      {isPrimary ? (
        <TableCell value={<LogoBlock className='table__logo' />} />
      ) : (
        <TableCell value={data.name} />
      )}

      <TableCell value={dataValue} />
    </div>
  )
}
