export const LINKS = {
  ARTICLES: '/articles',
  HOW_IT_WORKS: '/how-it-works',
  HOW_WE_ARE_DIFFERENT: '/how-we-are-different',
  HOW_WE_USE_AI: '/how-we-use-ai',
  PRICING: '/pricing',
  CONNECTORS: '/connectors',
  CHANGELOG: '/articles/changelog',
}

export const YOUTUBE_DEMO_VIDEO_ID = 'fRZrCEj7Vws'

export const DOCS_LINK = 'https://console.integration.app/docs'
export const REST_API_LINK =
  'https://console.integration.app/ref/rest-api/index.html'
export const JS_SDK_LINK = 'https://console.integration.app/ref/sdk/index.html'
export const REACT_SDK_LINK =
  'https://console.integration.app/ref/react/index.html'

export const IUBENDA_POLICY_URL =
  'https://www.iubenda.com/api/privacy-policy/16049587/no-markup'
export const IUBENDS_TERMS_AND_CONDITIONS_URL =
  'https://www.iubenda.com/api/terms-and-conditions/16049587/no-markup'

export const CONNECTORS_API = 'https://api.integration.app/connectors'

export const HIDDEN_CATEGORIES = ['pages']

export const HEADERS_IN_TOC = ['h2', 'h3']

export const calendlyCalendarUrl =
  'https://calendly.com/integration-app/30-min-demo?hide_gdpr_banner=1&embed_domain=calendly-embed.com&embed_type=PopupText&hide_event_type_details=1'

export const MIN_IN_SEC = 60
export const HOUR_IN_SEC = MIN_IN_SEC * 60

export const ARTICLE_REVALIDATE_TIME = 1 // sec
export const PAGE_REVALIDATE_TIME = MIN_IN_SEC // sec
export const BLOG_REVALIDATE_TIME = 30 * MIN_IN_SEC
export const CATEGORY_REVALIDATE_TIME = 1 * HOUR_IN_SEC
export const CONNECTORS_REVALIDATE_TIME = 6 * HOUR_IN_SEC
export const CONNECTOR_REVALIDATE_TIME = 6 * HOUR_IN_SEC
export const COMPETITOR_REVALIDATE_TIME = 6 * HOUR_IN_SEC
