import React, { HTMLAttributes, useEffect } from 'react'
import { IconCalendarEvent } from '@tabler/icons-react'
import { Loader } from 'routes/components/Loader'
import { calendlyCalendarUrl } from 'routes/constants'
import classes from 'routes/components/BookCalendly/BookCalendly.module.css'
import { useTrackingContext } from 'routes/components/Tracking/tracking-context'
import WebsitePopup from 'routes/components/WebsitePopup'
import { pushGtmEvent } from 'helpers/gtm'
import { BookCalendlyProvider, useBookCalendly } from './book-calendly-provider'
import { CalendlyEventData } from './types'
import {
  fetchCalendlyData,
  isCalendlyEvent,
  isCalendlyEventScheduled,
} from './calendly-helpers'
import { Button } from 'routes/components/Shared'

export { BookCalendlyProvider, useBookCalendly }

export default function BookCalendly() {
  const { showPopup, togglePopup, eventData, setEventData } = useBookCalendly()

  function handleClose() {
    // Clear event data on close
    setEventData(null)
    togglePopup()
  }

  return (
    <WebsitePopup
      onClose={handleClose}
      open={showPopup}
      className={!!eventData ? classes.container__small : classes.container}
    >
      {!!eventData ? <CalendlyScheduled eventData={eventData} /> : <Calendly />}
    </WebsitePopup>
  )
}

function Calendly() {
  return (
    <>
      <Loader className={classes.loader} />
      <BookCalendlyIframe className={classes.iframe}></BookCalendlyIframe>
    </>
  )
}

function CalendlyScheduled({ eventData }) {
  function formatDate(date) {
    const options = {
      hour: '2-digit',
      hour12: false,
      minute: '2-digit',
    }

    // @ts-expect-error TS(2769): Intl.DateTimeFormat options
    return new Intl.DateTimeFormat('en-US', options).format(date)
  }

  const startDate = new Date(eventData.start_time)
  const endDate = new Date(eventData.end_time)
  const startTime = formatDate(startDate)
  const endTime = formatDate(endDate)
  const dayMonthYear = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
  }).format(startDate)

  return (
    <div className={classes.scheduledEvent}>
      <img
        src={'/images/logo--black.svg'}
        alt=''
        className={classes.app_logo}
      />
      <h1>
        <Icon className={classes.icon} /> Thanks! You are scheduled
      </h1>
      <p>A calendar invitation has been sent to your email address.</p>
      <div className={classes.eventDetails}>
        <h2>{eventData?.name}</h2>
        <p>
          {startTime} — {endTime}, {dayMonthYear}
        </p>
      </div>
    </div>
  )
}

function Icon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      viewBox='0 0 28 28'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
    >
      <rect width='28' height='28' fill='#2D7509' rx='14' />
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='m8.167 14 4.166 4.167 8.334-8.334'
      />
    </svg>
  )
}

export function BookCalendlyButton({
  children,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  const { togglePopup } = useBookCalendly()
  return (
    <Button {...props} onClick={togglePopup}>
      <span>{children}</span>
      <IconCalendarEvent className={'icon'} />
    </Button>
  )
}

export function BookCalendlyIframe({ className }: { className?: string }) {
  const { addTrackingToUrl } = useTrackingContext()
  const { setEventData } = useBookCalendly()

  useEffect(() => {
    window.addEventListener('message', trackCalendlyEventData)
    return () => window.removeEventListener('message', trackCalendlyEventData)
  }, [])

  async function trackCalendlyEventData(e: MessageEvent<CalendlyEventData>) {
    if (!isCalendlyEvent(e) || !isCalendlyEventScheduled(e)) {
      return
    }

    pushGtmEvent('calendly_event_scheduled')
    const payloadEventUri = e?.data?.payload?.event?.uri
    const payloadInviteeUri = e?.data?.payload?.invitee?.uri

    if (payloadEventUri) {
      const event = await fetchCalendlyData(payloadEventUri)
      setEventData(event)
    }

    if (payloadInviteeUri) {
      const user = await fetchCalendlyData(payloadInviteeUri)
      pushGtmEvent('user_identify', {
        userName: user.name,
        userEmail: user.email,
      })
    }
  }

  return (
    <iframe
      src={addTrackingToUrl(calendlyCalendarUrl)}
      allowFullScreen
      className={className}
    ></iframe>
  )
}
