import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils'

export const Button = ({
  children,
  htmlType = 'button',
  type = 'secondary',
  className = '',
  onClick,
  disabled = false,
  ...attrs
}) => {
  const types = {
    primary: 'btn--primary',
    secondary: 'btn--secondary',
  }

  return (
    <button
      type={htmlType}
      className={classNames([
        'btn',
        types[type],
        className,
        disabled && 'btn--disabled',
      ])}
      onClick={!disabled ? onClick : () => {}}
      disabled={disabled}
      {...attrs}
    >
      <span className='btn__content'>{children}</span>
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  children: 'btn',
  onClick: () => {},
  className: '',
  type: '',
  disabled: false,
}
