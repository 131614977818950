export const productSubMenuArray = [
  {
    title: 'How It Works',
    href: '/how-it-works',
  },
  {
    title: 'How We Are Different',
    href: '/how-we-are-different',
  },
  {
    title: 'How We Use AI',
    href: '/how-we-use-ai',
  },
  {
    title: 'Changelog',
    href: '/articles/changelog',
  },
]
