import React, { useState } from 'react'
import YouTube from 'react-youtube'
import { classNames } from 'utils'
import { YOUTUBE_DEMO_VIDEO_ID } from 'routes/constants'
import WebsitePopup from 'routes/components/WebsitePopup'
import { BookCalendlyButton } from 'routes/components/BookCalendly'
import { Button, Link, Icon } from 'routes/components/Shared'

// Image has been optimized for web via https://kraken.io/web-interface with Lossy settings
import BannerImage1x from './banner-block@1x.jpg'
import BannerImage2x from './banner-block@2x.jpg'
import BannerImage3x from './banner-block@3x.jpg'

export const BannerBlock = ({ className, title, isBookButton }) => {
  return (
    <div className={classNames(['banner-block', className])}>
      <div className='banner-block__body'>
        <div className='page__background banner-block__background' />

        <h1
          className='banner-block__title'
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <div className='page__actions banner-block__actions'>
          <Link
            className='page__button banner-block__button banner-block__button--try'
            type='primary'
            href='https://console.integration.app/login'
          >
            Try For Free
          </Link>

          <Link
            className='page__button banner-block__button banner-block__button--read'
            type='secondary'
            href='https://console.integration.app/docs'
          >
            Read Documentation
          </Link>

          <WatchVideoButton>
            <span>Watch Demo</span>
            <Icon type='play-button' />
          </WatchVideoButton>

          {isBookButton && (
            <BookCalendlyButton
              className='page__button banner-block__button banner-block__button--book'
              type='secondary'
            >
              <span>Book a Demo</span>
            </BookCalendlyButton>
          )}
        </div>
        <div className='banner-block__visual'>
          <picture>
            {/* Retina macbooks */}
            <source
              media='(min-resolution: 2dppx) and (min-width: 600px) and (max-width: 999px)'
              srcSet={BannerImage2x.src}
            />
            <source
              media='(min-resolution: 2dppx) and (min-width: 1000px)'
              srcSet={BannerImage3x.src}
            />
            {/* Non Retina and mobiles */}
            <source media='(min-width: 1000px)' srcSet={BannerImage2x.src} />
            <source media='(max-width: 999px)' srcSet={BannerImage1x.src} />
            <img
              src={BannerImage1x.src}
              alt='landing banner'
              fetchpriority={'high'}
            />
          </picture>
        </div>
      </div>
    </div>
  )
}

function WatchVideoButton({ children }) {
  const [showVideo, setShowVideo] = useState(false)
  const toggleVideo = () => setShowVideo((v) => !v)

  return (
    <>
      <Button
        className='page__button banner-block__button banner-block__button--watch'
        type='secondary'
        onClick={toggleVideo}
      >
        {children}
      </Button>

      <WebsitePopup open={showVideo} onClose={toggleVideo}>
        <YouTube
          className={'h-full w-full'}
          iframeClassName={'h-full w-full'}
          videoId={YOUTUBE_DEMO_VIDEO_ID}
          opts={youtubeOptions}
        />
      </WebsitePopup>
    </>
  )
}

const youtubeOptions = {
  playerVars: {
    autoplay: 1,
    rel: 0,
  },
}
