import { Balancer } from 'react-wrap-balancer'
import { Banner } from 'routes/components/Layout/Banner'
import classes from './TechCrunchBanner.module.css'
import LogoImg from './logo.svg'

const LINK_TO_ARTICLE =
  'https://techcrunch.com/2023/11/07/integration-app-uses-llms-to-connect-apps-and-services-together/'

export function TechCrunchBanner() {
  return (
    <Banner>
      <a href={LINK_TO_ARTICLE} target={'_blank'}>
        <Balancer>
          🎉&ensp;Integration.app raised $3.5m in seed funding. Learn more on{' '}
          <img src={LogoImg.src} alt='' className={classes.logo} /> TechCrunch!
        </Balancer>
      </a>
    </Banner>
  )
}
