import { PropsWithChildren, useState } from 'react'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import WebsitePopup from 'routes/components/WebsitePopup'

import classes from './ZoomImage.module.css'

export function ZoomImage({
  children,
  link,
}: PropsWithChildren<{
  link?: string
}>) {
  const [open, setOpen] = useState(false)

  const handleOpen = useEventStopPropagation((e) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    e.preventDefault()
    setOpen(true)
  })
  const handleClose = useEventStopPropagation(() => {
    setOpen(false)
  })

  return (
    <>
      <a href={link} onClick={handleOpen} className={classes.zoomLink}>
        {children}
      </a>
      <WebsitePopup
        backdropClassName={classes.backdrop}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={classes.container} onClick={handleClose}>
          <img
            src={link}
            alt=''
            onClick={useEventStopPropagation(true)}
            className={classes.image}
          />
        </div>
      </WebsitePopup>
    </>
  )
}
