import { ReactNode } from 'react'
import classes from './Container.module.css'
import clsx from 'clsx'

type ContainerProps = {
  children: ReactNode
  className?: string
  paddingTop?: boolean
  paddingBottom?: boolean
}

export default function Container({ children, ...props }: ContainerProps) {
  return (
    <ContainerWrapper {...props}>
      <ContainerContent>{children}</ContainerContent>
    </ContainerWrapper>
  )
}

export function ContainerWrapper({
  children,
  className,
  paddingTop,
  paddingBottom,
}: ContainerProps) {
  return (
    <>
      <div
        className={clsx(
          classes.wrapper,
          paddingTop && classes.wrapper__paddingTop,
          paddingBottom && classes.wrapper__paddingBottom,
          className,
        )}
      >
        {children}
      </div>
    </>
  )
}

export function ContainerContent({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  return <div className={clsx(classes.container, className)}>{children}</div>
}
