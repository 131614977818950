import { PropsWithChildren } from 'react'
import { Provider as ReactWrapProvider } from 'react-wrap-balancer'
import { ViewportProvider } from 'hooks'
import BookCalendly, {
  BookCalendlyProvider,
} from 'routes/components/BookCalendly'
import { TechCrunchBanner } from 'routes/components/TechCrunchBanner'
import { Header, Footer } from '../components/Layout'
import { NoRobotsMetatag } from 'routes/components/HeadMetatags'

export function DefaultPageLayout({
  className,
  hideAnnouncement,
  isDraft,
  children,
}: PropsWithChildren<{
  className?: string
  hideAnnouncement?: boolean
  isDraft?: boolean
}>) {
  return (
    <ViewportProvider>
      <ReactWrapProvider>
        <BookCalendlyProvider>
          {isDraft && <NoRobotsMetatag />}
          {!hideAnnouncement && <TechCrunchBanner />}
          <Header />
          <main className={className}>{children}</main>
          <Footer />
          <BookCalendly />
        </BookCalendlyProvider>
      </ReactWrapProvider>
    </ViewportProvider>
  )
}
