import { PropsWithChildren, useEffect } from 'react'
import useEventStopPropagation from '@integration-app/ui/hooks/useEventStopPropagation'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'
import { Portal } from '@integration-app/ui/Portal'
import classes from './WebsitePopup.module.css'
import clsx from 'clsx'

export default function WebsitePopup({
  children,
  backdropClassName,
  className,
  open,
  onClose,
}: PropsWithChildren<{
  backdropClassName?: string
  className?: string
  open?: boolean
  onClose: () => void
}>) {
  useEffect(() => {
    if (window === undefined) return
    const body = window.document.querySelector('body')
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    body.classList?.[open ? 'add' : 'remove'](classes.bodyWithVideo)
  }, [open])

  if (!open) return null
  return (
    <Portal>
      <div
        className={clsx(classes.backdrop, backdropClassName)}
        onClick={onClose}
      >
        <div className={classes.wrapper}>
          <div
            className={clsx(classes.container, className)}
            onClick={useEventStopPropagation(true)}
          >
            <button
              type={'button'}
              className={classes.closeButton}
              onClick={onClose}
              title={'Close popup'}
            >
              <SvgIcon type={SvgIconType.Close} />
            </button>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  )
}
