import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from 'utils'
import ICONS from './icons'

export const Icon = ({ type, className = '', ...attrs }) => {
  const icon =
    ICONS[type].format === 'data' ? (
      <path d={ICONS[type].path} />
    ) : (
      ICONS[type].markup
    )

  return (
    <svg
      className={classNames(['icon', className])}
      viewBox={ICONS[type].viewbox}
      fill={ICONS[type].initColor || ''}
      {...attrs}
    >
      {icon}
    </svg>
  )
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Icon.defaultProps = {
  type: 'plus',
  className: '',
}
