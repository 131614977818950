export const developersSubMenuArray = [
  {
    title: 'Developer Documentation',
    href: 'https://console.integration.app/docs',
  },
  {
    title: 'Rest API',
    href: 'https://console.integration.app/ref/rest-api/index.html',
  },
]
