import React from 'react'
import { Icon } from 'routes/components/Shared'
import { Markdown } from 'routes/components/Markdown'

export const Accordion = ({ array }) => {
  return (
    <div className='accordion-block'>
      <ul className='accordion-block__items'>
        {array.map((item, index) => {
          return (
            <li className='accordion-block__item' key={index}>
              <input
                className='accordion-block__item-checkbox'
                id={`check-${index}`}
                type='checkbox'
              />

              <label
                className='accordion-block__item-title'
                htmlFor={`check-${index}`}
              >
                {item.title}
                <Icon
                  className='accordion-block__item-icon'
                  type='menu-check'
                />
              </label>

              <div className='accordion-block__item-content'>
                <Markdown>{item.content}</Markdown>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
