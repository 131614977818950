import Slugger from 'github-slugger'

import { ArticleSidebarHeader } from 'routes/components/Article/ArticleSidebar'
import { useArticleActiveHeaderId } from 'routes/components/Article/ArticleActiveHeader'

import classes from './ArticleToc.module.css'
import { HEADERS_IN_TOC } from 'routes/constants'

type Link = {
  depth: number
  value: string
  attributes: Record<string, string>
  children: Link[]
}

export function ArticleTocRootUl({ children }) {
  return (
    <>
      <ArticleSidebarHeader>On this page</ArticleSidebarHeader>
      <ul className={classes.toc}>{children}</ul>
    </>
  )
}

function Links({ links }: { links?: Link[] }) {
  if (!links || links.length === 0) return null
  return (
    <>
      {links.map((link, id) => (
        <Link key={id} link={link} />
      ))}
    </>
  )
}

function Link({ link }: { link: Link }) {
  const slugger = new Slugger()
  const slug = slugger.slug(link.value)
  const activeHeaderId = useArticleActiveHeaderId()

  return (
    <>
      {HEADERS_IN_TOC.includes(`h${link.depth}`) && (
        <li data-level={`h${link.depth}`}>
          <a href={`#${slug}`} aria-selected={activeHeaderId === slug}>
            {link.value}
          </a>
        </li>
      )}
      <Links links={link.children} />
    </>
  )
}
