import React from 'react'
import PropTypes from 'prop-types'
import NextLink from 'next/link'
import { classNames } from 'utils'

export const Link = ({
  children,
  type = '',
  className = '',
  href,
  target = '',
  ...attrs
}) => {
  const types = {
    primary: 'link--primary',
    secondary: 'link--secondary',
    line: 'link--line',
    noLine: 'link--no-line',
  }

  if (target === '_blank' || href.startsWith('http')) {
    return (
      <a
        className={classNames(['link', type && types[type], className])}
        href={href}
        target={!!target ? target : undefined}
        {...attrs}
      >
        <span className='link__content'>{children}</span>
      </a>
    )
  }

  return (
    <NextLink href={href} {...attrs}>
      <a
        className={classNames(['link', type && types[type], className])}
        target={!!target ? target : undefined}
      >
        <span className='link__content'>{children}</span>
      </a>
    </NextLink>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
}

Link.defaultProps = {
  children: 'link',
  className: '',
  type: '',
  href: '#',
  target: '_blank',
}
