import CorticalVenturesLogo from './cortical-ventures-logo.png'
import CrewCapitalLogo from './crew-capital-logo.png'
import DataRobotLogo from './data-robot-logo.png'
import SeedCampLogo from './seed-camp-logo.png'
import UiPathLogo from './ui-path-logo.png'

export const partnersArray = [
  {
    name: 'cortical-ventures',
    url: 'https://cortical.vc/',
    logo: CorticalVenturesLogo,
  },
  {
    name: 'crew-capital',
    url: 'https://crew.vc/',
    logo: CrewCapitalLogo,
  },
  {
    name: 'ui-path',
    url: 'https://www.uipath.com/',
    logo: UiPathLogo,
  },
  {
    name: 'seed-camp',
    url: 'https://seedcamp.com/',
    logo: SeedCampLogo,
  },
  {
    name: 'data-robot',
    url: 'https://www.datarobot.com/',
    logo: DataRobotLogo,
  },
]
