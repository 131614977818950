import React, { useState } from 'react'
import { classNames } from 'utils'
import { Button, LogoBlock, Icon } from 'routes/components/Shared'
import { HeaderMobileMenu } from './HeaderMobileMenu'
import { HeaderNavigation } from './HeaderNavigation'
import { HeaderActions } from './HeaderActions'

export const Header = () => {
  const [isMobileMenu, setIsMobileMenu] = useState(false)

  const onMenuButtonClick = () => {
    if (!isMobileMenu) {
      setIsMobileMenu(true)
    } else {
      setIsMobileMenu(false)
    }
  }

  return (
    <header className={classNames(['header', isMobileMenu && 'header--open'])}>
      <div className='container'>
        <div className='header__body'>
          <div className='header__block header__block--logo'>
            <Button
              className='btn--menu header__menu-button'
              onClick={onMenuButtonClick}
              aria-label='Menu'
            >
              <Icon type={isMobileMenu ? 'menu-close' : 'menu-button'} />
            </Button>

            <LogoBlock className='header__logo' />
          </div>

          <div className='header__block header__block--navigation'>
            <HeaderMobileMenu />
            <HeaderNavigation />
          </div>

          <div className='header__block header__block--actions'>
            <HeaderActions />
          </div>
        </div>
      </div>
    </header>
  )
}
