import React from 'react'
import { HeaderNavigation } from './HeaderNavigation'
import { HeaderActions } from './HeaderActions'

export const HeaderMobileMenu = () => {
  return (
    <div className='header__mobile-menu'>
      <HeaderNavigation />
      <HeaderActions isMobileMenu />
    </div>
  )
}
