import { ReactNode } from 'react'
import clsx from 'clsx'
import { DefaultPageLayout } from './DefaultPage'
import Container from 'routes/components/Layout/Container'
import classes from './InnerPage.module.css'

export function InnerPageLayout({
  className,
  hideAnnouncement,
  isDraft,
  children,
}: {
  className?: string
  hideAnnouncement?: boolean
  isDraft?: boolean
  children: ReactNode
}) {
  return (
    <DefaultPageLayout hideAnnouncement={hideAnnouncement} isDraft={isDraft}>
      <Container className={clsx(classes.bg, className)} paddingBottom>
        {children}
      </Container>
    </DefaultPageLayout>
  )
}
