import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useViewport } from 'hooks'
import { classNames } from 'utils'
import { Button } from 'routes/components/Shared'

export const Tabs = ({ className, tabsData, activeTab, onTabClick }) => {
  const { isMobile } = useViewport()
  const tabsRef = useRef(null)

  const onClick = (tabObject, event) => {
    event.preventDefault()
    const isTabActive = activeTab === tabObject?.tab

    if (isTabActive) return false

    onTabClick(tabObject, event)
  }

  return (
    <div ref={tabsRef} className={classNames(['tabs', className])}>
      <div className='tabs__body'>
        {tabsData.map((tab) => (
          <Button
            className={classNames([
              'tabs__item',
              tab.tab === activeTab && 'tabs__item--active',
            ])}
            key={tab.tab}
            onClick={(event) => onClick(tab, event)}
          >
            {isMobile && tab.mobileTitle ? tab.mobileTitle : tab.title}
          </Button>
        ))}
      </div>
    </div>
  )
}

Tabs.defaultProps = {
  className: '',
  activeTab: '',
  onTabClick: () => {},
  tabsData: [],
}

Tabs.propTypes = {
  className: PropTypes.string,
  onTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  tabsData: PropTypes.instanceOf(Array).isRequired,
}
