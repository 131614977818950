import { AnchorHTMLAttributes } from 'react'
import { useTrackingContext } from './Tracking/tracking-context'
import { Link } from 'routes/components/Shared'

export default function LoginLink({
  children,
  className,
  ...props
}: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { addTrackingToUrl } = useTrackingContext()
  return (
    <Link
      href={addTrackingToUrl(`${process.env.https://console-staging.integration.app}/login`)}
      {...props}
      className={className}
    >
      {children}
    </Link>
  )
}
