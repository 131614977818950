import React from 'react'
import { Link } from 'routes/components/Shared'
import { BookCalendlyButton } from 'routes/components/BookCalendly'

export const HeaderActions = ({ isMobileMenu }) => {
  return (
    <div className='header__actions'>
      <BookCalendlyButton
        type={isMobileMenu ? 'secondary' : 'primary'}
        className='header__book-button'
      >
        Book a Demo
      </BookCalendlyButton>
      <Link
        className='header__log-button'
        type={isMobileMenu && 'secondary'}
        href='https://console.integration.app/login'
        target='_blank'
      >
        Log In
      </Link>

      <Link
        className='header__sign-button'
        type={isMobileMenu ? 'primary' : 'secondary'}
        href='https://console.integration.app/login'
        target='_blank'
      >
        Sign Up
      </Link>
    </div>
  )
}
