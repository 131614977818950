import React from 'react'
import { classNames } from 'utils'
import { Link } from 'routes/components/Shared'
import { partnersArray } from './partnersArray'

export const PartnersBlock = ({ className, title }) => {
  return (
    <div className={classNames(['partners-block', className])}>
      <div className='flex-column partners-block__body'>
        <h2 className='page__title partners-block__title'>{title}</h2>

        <div className='partners-block__items'>
          <ul className='flex-column partners-block__items-list'>
            {partnersArray.map((item, index) => {
              return (
                <li className='partners-block__item' key={index}>
                  <Link href={item.url}>
                    <img
                      loading={'lazy'}
                      src={item.logo.src}
                      alt='partner logo'
                    />
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
