import React from 'react'
import { useViewport } from 'hooks'
import { Icon } from 'routes/components/Shared'
import LoginLink from 'routes/components/LoginLink'
import { BookCalendlyButton } from 'routes/components/BookCalendly'

export const AdvertiseBlock = () => {
  const { isMobile } = useViewport()

  return (
    <div className='advertise-block'>
      <div className='advertise-block__body'>
        <div className='advertise-block__background'></div>

        <div className='flex-column advertise-block__content'>
          <h2 className='page__title advertise-block__title'>
            Try our AI-powered integration development environment for free
          </h2>

          <div className='page__actions advertise-block__actions'>
            <LoginLink
              className='page__button advertise-block__button'
              type='primary'
            >
              Try It
            </LoginLink>

            <BookCalendlyButton
              className='page__button advertise-block__button'
              type='secondary'
            >
              Book a Demo
            </BookCalendlyButton>
          </div>
        </div>

        {!isMobile && (
          <Icon className='advertise-block__visual' type='advertise-visual' />
        )}
      </div>
    </div>
  )
}
