import Container from 'routes/components/Layout/Container'
import classes from './Banner.module.css'

export function Banner({ children }) {
  return (
    <Container className={classes.bannerContainer}>
      <div className={classes.banner}>{children}</div>
    </Container>
  )
}
