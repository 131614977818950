import React from 'react'
import SvgIcon, { SvgIconType } from '@integration-app/ui/SvgIcon'

export const TableCell = ({ value }) => {
  return typeof value === 'boolean' ? (
    <span className='table__cell'>
      <span className='table__icon'>
        <SvgIcon type={value ? SvgIconType.Check : SvgIconType.Close} />
      </span>
    </span>
  ) : (
    <span className='table__cell'>{value}</span>
  )
}
