import React, { useState } from 'react'
import { useViewport } from 'hooks'
import { classNames } from 'utils'
import { Link, Icon } from 'routes/components/Shared'
import { productSubMenuArray } from 'routes/components/Layout/Header/productSubMenuArray'
import { developersSubMenuArray } from 'routes/components/Layout/Header/developersSubMenuArray'

export const HeaderNavigation = () => {
  const [openSubMenu, setOpenSubMenu] = useState(null)
  const { isTablet } = useViewport()

  const onSubMenuButtonClick = (index) => {
    if (isTablet) {
      setOpenSubMenu((prevIndex) => (prevIndex === index ? null : index))
    }
  }

  const renderSubMenu = (array) => {
    return (
      <div className='header__submenu'>
        <ul className='flex-column header__submenu-list'>
          {array.map((item, index) => {
            return (
              <li className='header__submenu-item' key={index}>
                <Link href={item.href} target='_self'>
                  {item.title}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <nav className='header__menu'>
      <ul className='flex-column header__menu-list'>
        <li
          className={classNames([
            'header__menu-item',
            'header__menu-item--dropdown',
            openSubMenu === 0 && 'header__menu-item--open',
          ])}
          onClick={() => onSubMenuButtonClick(0)}
        >
          <span>
            <Icon type='menu-check' />
            Product
          </span>
          {renderSubMenu(productSubMenuArray)}
        </li>

        <li
          className={classNames([
            'header__menu-item',
            'header__menu-item--dropdown',
            openSubMenu === 1 && 'header__menu-item--open',
          ])}
          onClick={() => onSubMenuButtonClick(1)}
        >
          <span>
            Developers
            <Icon type='menu-check' />
          </span>
          {renderSubMenu(developersSubMenuArray)}
        </li>

        <li className='header__menu-item'>
          <Link href='/connectors' target='_self'>
            Connectors
          </Link>
        </li>

        <li className='header__menu-item'>
          <Link href='/pricing' target='_self'>
            Pricing
          </Link>
        </li>

        <li className='header__menu-item'>
          <Link href='/articles' target='_self'>
            Blog
          </Link>
        </li>
      </ul>
    </nav>
  )
}
